.panel {
	padding: 1rem 2rem;

	.select2-container--default .select2-selection--single {
		border: 1px solid rgba(70, 77, 82, 0.4);	
		background-color: @terceary-color;	
	}

	.select2-container--disabled.select2-container--default .select2-selection--single {
		background-color: #eee;
	}
}

.panel-default,
.wrapper .pagination {
	.panel;
	background: @terceary-color;
	box-shadow: 0 0px 1px rgba(0,0,0,.1);

	&.list {
		margin-top: 2rem;
	}
}

.wrapper .pagination {
	margin-top: 2rem;
}


.panel-secondary {
	.panel;
	background: @secondary-color;
	box-shadow: 0 0px 1px rgba(0,0,0,.1);

	&.list {
		margin-top: 2rem;
	}
}

.panel-terceary {
	.panel;
	background: @terceary-color;
	box-shadow: 0 0px 1px rgba(0,0,0,.1);

	&.list {
		margin-top: 2rem;
	}
}

.panel-title-primary {
	margin-bottom: 0;
	padding: .5rem 1rem;
	background: @primary-color;
	color: @terceary-color;

	a {
		font-size: 1.4rem;
	    line-height: 1.4rem;
	    color: @terceary-color;
	    text-decoration: none;
	    margin-top: .5rem;
	}
}

