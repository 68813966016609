.form-row {
  margin: 1rem 0;

	input[type="text"],
	input[type="password"],
	input[type="date"],
	select,
	textarea {
		width: 100%;
		padding: 1rem 1.5rem;
		border: 1px solid fade(@tenternary-color, 40);
    border-radius: 5px;

    &:disabled {
      background: @thirteenternary-color;
      cursor: not-allowed;
    }
	}

	label {
		font-size: 1.7rem;

    &.error {
      font-size: 1.5rem;
      color: @primary-color;
    }
	}
}

[type="checkbox"]:not(:checked),
[type="checkbox"]:checked {
  position: absolute;
  left: -9999px;
}
[type="checkbox"]:not(:checked) + label,
[type="checkbox"]:checked + label {
  position: relative;
  padding-left: 25px;
  cursor: pointer;
}


[type="checkbox"]:not(:checked) + label:before,
[type="checkbox"]:checked + label:before {
  content: '';
  position: absolute;
  left:0; top: 2px;
  width: 17px; height: 17px;
  border: 1px solid #aaa;
  background: #f8f8f8;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.3)
}

[type="checkbox"]:not(:checked) + label:after,
[type="checkbox"]:checked + label:after {
  content: '✔';
  position: absolute;
  top: 3px; 
  left: 2px;
  font-size: 18px;
  line-height: 0.8;
  color: @primary-color;
  transition: all .2s;
}

[type="checkbox"]:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
[type="checkbox"]:checked + label:after {
  opacity: 1;
  transform: scale(1);
}

[type="checkbox"]:disabled:not(:checked) + label:before,
[type="checkbox"]:disabled:checked + label:before {
  box-shadow: none;
  border-color: #bbb;
  background-color: #ddd;
}

[type="checkbox"]:disabled:checked + label:after {
  color: #999;
}
[type="checkbox"]:disabled + label {
  color: #aaa;
}