@import "core.less";

//Modules
@import "../pages/cms/modules/header.less";
@import "../pages/cms/modules/footer.less";
@import "../pages/cms/modules/form.less";
@import "../pages/cms/modules/buttons.less";
@import "../pages/cms/modules/panels.less";
@import "../pages/cms/modules/table.less";

.info {
    margin: 0;
    padding: .5rem 0;
	font-size: 1.5rem;
}